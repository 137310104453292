'use client'
import { PasswordField } from '@/app/account/components/components/password-field'
import { loginFormSchema } from '@/app/account/components/schemas/login-form-schema'
import { LoginCommonProps } from '@/app/account/components/types/login-common-props'
import { LoginUserForm } from '@/app/account/components/types/login-user-form'
import { useTracking } from '@/screens/common/use-tracking'
import { useGetUser } from '@/services/api/users/use-get-user'
import { zodResolver } from '@hookform/resolvers/zod'
import { signIn, useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { pki, util } from 'node-forge'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Spinner } from 'ui'
import { useFetcher } from 'utils'
import { TextField } from './text-field'
import { useTracking as useTrk } from '@repo/tracking/contexts/tracking'
import { TrackEvent } from '@repo/tracking/types/TrackEvents'

export function LoginForm({ pemKey }: LoginCommonProps) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginUserForm>({
    resolver: zodResolver(loginFormSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })
  const { addTrack } = useTrk()
  const { data: session, status } = useSession()
  const router = useRouter()
  const setToken = useFetcher((state) => state.setAuthToken)
  const authToken = useFetcher((state) => state.authToken)
  const tracking = useTracking()

  const { data: userInfo } = useGetUser(
    !!session && !!session?.user && session?.user?.token === authToken
  )
  useEffect(() => {
    if (
      session &&
      session.user &&
      session.user.token &&
      session.user.token !== authToken
    ) {
      setToken(session.user.token)
    }
  }, [session, setToken, authToken])
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(() => {
    if (userInfo && userInfo?.id! > 0) {
      router.push('/')
    }
  }, [userInfo, router])

  const onSubmit = async (data: LoginUserForm) => {
    try {
      if (isSubmit) return
      setIsSubmit(true)
      const publicKey = pki.publicKeyFromPem(pemKey)

      const res = await signIn('credentials', {
        email: data.email,
        password: util.encode64(publicKey.encrypt(data.password)),
        redirect: false,
      })

      addTrack({
        event: TrackEvent.LOGIN,
      })
      tracking.trackEvent({
        eventName: 'signin',
        parameters: {},
      })

      if (res?.error) {
        setError('password', { message: res.error })
      }
    } catch (ex) {
      setError('password', { message: ex.message })
    }
    setIsSubmit(false)
  }

  return (
    <form
      className="flex h-[452px] w-full flex-col justify-center rounded-[12px] bg-white px-[20px] py-[40px] font-sans shadow-[0px_2px_6px_#EBEBF5] sm:px-[48px] sm:py-[56px]"
      onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-[20px] flex w-full flex-col">
        <h1 className="text-[20px] font-semibold">{'Accede a tu cuenta'}</h1>
      </div>

      <div className="mb-[24px]">
        <TextField
          type={'email'}
          label={'Correo electrónico'}
          placeholder={'Correo electrónico...'}
          {...register('email')}
          error={errors.email?.message}
        />
      </div>
      <div className="mb-[32px]">
        <PasswordField
          name={'password'}
          placeholder={'Contraseña...'}
          label={'Contraseña'}
          helper={{
            link: '/account/email',
            text: 'He olvidado la contraseña',
          }}
          error={errors.password?.message}
          register={register('password')}
        />
      </div>
      <div className="flex flex-col">
        <button
          disabled={isSubmit}
          className="flex h-[42px] items-center justify-center rounded-3xl bg-[#5368E5] text-[16px] leading-[19px] text-white"
          type="submit">
          {isSubmit || status === 'authenticated' ? (
            <Spinner color="white" />
          ) : (
            'Iniciar sesión'
          )}
        </button>
      </div>
      <div className="mt-[16px] flex items-center justify-center">
        <p className="text-[14px] text-[#969696]">
          {'¿No tienes cuenta? '}
          <Link href={'https://formacion.ninja/'} className="text-[#5368E5]">
            {'Entrar en Ninja'}
          </Link>
        </p>
      </div>
    </form>
  )
}
