import { forwardRef, InputHTMLAttributes, useState } from 'react'
import { EyeOffFilled } from 'ui'
import clsx from 'clsx'
import { TextField } from './text-field'
import { EyeFilled } from 'ui/icons/eye-filled'
import { TextFieldHelper } from 'ui/components/forms/text-field'
import { UseFormRegisterReturn } from 'react-hook-form'

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  loading?: boolean
  helper?: TextFieldHelper
  label?: string
  error?: string
  placeholder?: string
  extraClassName?: string
  register: UseFormRegisterReturn<string>
}

export const PasswordField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextFieldImpl(
    {
      name,
      register,
      loading,
      label,
      error,
      helper,
      required,
      extraClassName,
      ...props
    },
    ref
  ) {
    const [showPassword, setShowPassword] = useState(false)

    return (
      <div className={'relative'}>
        <TextField
          type={showPassword ? 'text' : 'password'}
          label={label}
          helper={helper}
          error={error}
          required={required}
          {...register}
          {...props}
        />
        <div
          className="fa-solid absolute bottom-[17px] right-3 cursor-pointer p-3"
          onClick={() => setShowPassword(!showPassword)}>
          <EyeOffFilled
            className={clsx(
              'h-[18px] w-[18px]',
              showPassword ? 'hidden' : 'visible'
            )}
          />
          <EyeFilled
            className={clsx(
              'h-[18px] w-[18px]',
              showPassword ? 'visible' : 'hidden'
            )}
          />
        </div>
      </div>
    )
  }
)
