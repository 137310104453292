export enum FormattedDateType {
  SHORT = 'short',
  LONG = 'long',
}

export const getFormattedDate = (
  date: Date | string,
  type?: FormattedDateType
) => {
  if (!date) return ''

  if (typeof date === 'string') date = new Date(date)

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }

  switch (type) {
    case FormattedDateType.SHORT:
      const day = date.toLocaleString('es-ES', { day: 'numeric' })
      const month = date.toLocaleString('es-ES', { month: 'long' })
      const year = date.toLocaleString('es-ES', { year: 'numeric' })

      return `${day} ${month} ${year}`
    default:
      const formatoFecha = new Intl.DateTimeFormat('es-ES', options)

      return formatoFecha.format(date)
  }
}

export const getTimeAgo = (date: Date) => {
  const now = new Date()
  const diff = (now as any) - (date as any)

  const seconds = Math.floor(diff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (years > 0) {
    return 'Hace ' + years + (years === 1 ? ' año' : ' años')
  } else if (months > 0) {
    return 'Hace ' + months + (months === 1 ? ' mes' : ' meses')
  } else if (days > 0) {
    return 'Hace ' + days + (days === 1 ? ' día' : ' días')
  } else if (hours > 0) {
    return 'Hace ' + hours + (hours === 1 ? ' hora' : ' horas')
  } else if (minutes > 0) {
    return 'Hace ' + minutes + (minutes === 1 ? ' minuto' : ' minutos')
  } else {
    return 'Hace unos segundos'
  }
}

export const addHoursToDate = (date: Date, hours: number) => {
  return new Date(date.getTime() + 60 * 60 * 1000 * hours)
}

export const isRecent = (timestamp: Date, minutes) => {
  const diferenciaEnMs = new Date().getTime() - timestamp.getTime()
  const cincoMinutosEnMs = minutes * 60 * 1000

  return diferenciaEnMs < cincoMinutosEnMs
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
