import { User } from 'types/web'
import { useFetcher } from 'utils'
import { getUserWithExamConfigQuery } from './get-user-by-id-with-exam-config.query'
import { signOut } from 'next-auth/react'

export const getUser = async (): Promise<Partial<User>> => {
  const fetcher = useFetcher.getState().authFetcher

  const data = await fetcher({
    query: getUserWithExamConfigQuery,
    errorHandler: (errors) => {
      if (
        errors[0].message === 'Unauthorized' &&
        typeof window !== 'undefined'
      ) {
        localStorage.removeItem('fetch-storage')
        signOut()
        // window.location.assign('/autorizar?token=null')
      }
    },
  })

  return data.getUser
}
