import { RawEventStatus } from 'database'

export enum TrackEvent {
  USAGE = 'USAGE',
  PAGE_VIEW = 'PAGE_VIEW',
  CLOSE_SESSION = 'CLOSE_SESSION',
  LOGIN = 'LOGIN',
  SESSION_TIME = 'SESSION_TIME',
  ANSWER_QUESTION = 'ANSWER_QUESTION',
}

export type RawEvents = {
  id: string
  userId: number
  event: string
  value: string
  data?: {}
  platformId: number
  examId: number
  processStatus: RawEventStatus
  createdAt: Date
}
export type RawEventsGroupedDB = {
  user_id: number
  platform_id: number
  exam_id: number
  event: string
  value: string
  data: {}
  created_at: string
}

export type EventsKpi = {
  items: RawEvents[]
  total: number
  event: string
  value: string
  platformId: number
  examId: number
  userId: number
  created_at: Date
}
export type UserActivity = {
  user_id: number
  platform_id: number
  exam_id: number
  first_date: Date
  activity_dates: Date[]
}
