export const WrongIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}>
    <circle cx={7} cy={7} r={7} fill="#DB4E56" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.75}
      d="m9.625 4.375-5.25 5.25M4.375 4.375l5.25 5.25"
    />
  </svg>
)
