'use client'
import { LoginForm } from './components/login-form'
import { Suspense } from 'react'

export function Login({ pemKey }: { pemKey: string }) {
  return (
    <Suspense>
      <LoginForm pemKey={pemKey} />
    </Suspense>
  )
}
