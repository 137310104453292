'use client'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { addTracking, TrackingProps } from '../actions/addTrk'
import { useSession } from '@repo/users'
import { usePathname } from 'next/navigation'
import { TrackEvent } from '../types/TrackEvents'

export type TrackingContextProps = {
  addTrack: ({}: TrackingProps) => void
  isLoading: boolean
}
const TrackingContext = createContext<TrackingContextProps>({
  addTrack: ({}: TrackingProps) => {},
  isLoading: false,
})

export const useTracking = () => useContext(TrackingContext)

export function TrackingProvider({ children }: { children: React.ReactNode }) {
  const { session, isLoading: isSessionLoading } = useSession()
  const [isLoading, setIsLoading] = useState(false)
  const pathname = usePathname()
  const [eventQueue, setEventQueue] = useState<TrackingProps[]>([])

  const addTrack = useCallback(
    ({
      event,
      value,
      data,
    }: {
      event: TrackEvent
      value?: string
      data?: any
    }) => {
      setEventQueue((prev) => [...prev, { event, value, data }])
    },
    []
  )

  const processQueue = useCallback(
    async (userId: number, platformId: number, opoId: number) => {
      if (!userId || eventQueue.length === 0) return

      setIsLoading(true)
      try {
        addTracking({ userId, events: eventQueue, platformId, opoId })

        setEventQueue([])
      } catch (error) {
        console.error('Error processing tracking queue', error)
      } finally {
        setIsLoading(false)
      }
    },
    [session, eventQueue]
  )

  useEffect(() => {
    if (
      session?.user?.id &&
      session.user.platformId &&
      session.user.opoId &&
      !isSessionLoading
    ) {
      processQueue(session.user.id, session.user.platformId, session.user.opoId)
    }
  }, [session, isSessionLoading, processQueue])

  useEffect(() => {
    const handlePageView = () => {
      const url = `${pathname}`
      addTrack({
        event: TrackEvent.PAGE_VIEW,
        value: url,
      })
    }

    handlePageView()
  }, [pathname]) // Dependencias del efecto

  return (
    <TrackingContext.Provider
      value={{
        addTrack,
        isLoading,
      }}>
      {children}
    </TrackingContext.Provider>
  )
}
