import Link from 'next/link'
import { forwardRef, InputHTMLAttributes } from 'react'
import { WrongIcon } from './wrong-icon'
import { Placeholder } from 'ui'
import clsx from 'clsx'

export enum TextFieldStyle {
  NORMAL,
  SOFT,
}
export type TextFieldHelper = {
  text: string
  link: string
}

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  loading?: boolean
  helper?: TextFieldHelper
  label?: string
  error?: string
  extraClassName?: string
  fieldStyle?: TextFieldStyle
  isLabelDisabled?: boolean
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextFieldImpl(
    {
      loading,
      label,
      error,
      helper,
      required,
      extraClassName,
      fieldStyle,
      isLabelDisabled,
      ...props
    },
    ref
  ) {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          {!isLabelDisabled && (
            <label
              className={clsx(
                'p-2 pl-0 font-semibold leading-[17px]',
                fieldStyle === TextFieldStyle.SOFT
                  ? 'text-prof_l opacity-70 bp1:text-prof_2xl'
                  : 'text-[14px]'
              )}
              htmlFor={props.name}>
              {!loading && label ? (
                label
              ) : (
                <Placeholder className="h-[17px] w-full" />
              )}
            </label>
          )}

          {helper && (
            <div className="flex justify-end">
              <Link
                href={helper.link}
                className={clsx(
                  'p-2 pr-0 font-medium leading-[17px] text-[#5368E5]',
                  fieldStyle === TextFieldStyle.SOFT
                    ? 'text-prof_l font-normal bp1:text-prof_2xl'
                    : 'text-[14px]'
                )}>
                {helper.text}
              </Link>
            </div>
          )}
        </div>
        {loading ? (
          <Placeholder className="h-[42px] w-full" />
        ) : (
          <input
            className={clsx(
              'h-[42px] rounded-md bg-[#F7F7F7] p-2 px-[16px] py-[11px] font-sans text-[16px] placeholder-gray-500 placeholder:leading-[19px] focus:border-study',
              extraClassName
            )}
            {...props}
            ref={ref}
          />
        )}
        <div className="h-[17px]">
          {error && (
            <div className="flex items-center gap-2">
              <WrongIcon />
              <p className="p-1 pl-0 text-[14px] text-[#DB4E56]">{error}</p>
            </div>
          )}
        </div>
      </div>
    )
  }
)
