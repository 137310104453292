export const getUserWithExamConfigQuery = /* GraphQL */ `
  query getUserWithExamConfig {
    getUser {
      id
      name
      wordpressUrl
      wordpressId
      wrongAnswersCount
      currentExam {
        id
        usersCount
        name
        answersPerQuestion
        examTimeLimit
        examTotalQuestions
        correctAnswerValue
        wrongAnswerValue
        emptyAnswerValue
      }
    }
  }
`
